import React from "react"
import Layout from "../components/UI/Layout"
import Head from "../components/Head"
import AboutUsComponent from "../components/Sections/AboutUs"
import SimpleReactLightbox from "simple-react-lightbox"

const AboutUs = () => {
  return (
    <Layout>
      <Head
        description="About Us 👉 We have been here since 2012 support in projects, code analysis, developing web and mobile apps, devops services and cloud solutions."
        pageTitle="About Us | Meet Our Team"
        keywords="software, oakfusion"
      />
      <SimpleReactLightbox>
        <AboutUsComponent />
      </SimpleReactLightbox>
    </Layout>
  )
}

export default AboutUs
