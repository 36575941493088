import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 650px) {
    justify-content: center;
    align-items: center;
  }
`
export const Number = styled.div`
  font-size: ${props => props.theme.fontSizes.big};
  color: ${props => props.theme.colors.white};
`
