import React from "react"
import { Wrapper, Number } from "./style"
import { P } from "../../../../globalStyle"
import ICounter from "./types"
import { useTheme } from "styled-components"
import { useSpring, animated } from "react-spring"

const Counter = (props: ICounter) => {
  const { number, text } = props
  const theme = useTheme()
  return (
    <Wrapper>
      <Number>{number}</Number>
      <P color={theme.colors.white}>{text}</P>
    </Wrapper>
  )
}

export default Counter
