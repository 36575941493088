import styled from "styled-components"

export const Container = styled.div`
  width: 1470px;
  @media (max-width: 1470px) {
    width: 100%;
  }
`
export const LinkedInIcon = styled.div`
  margin-left: 10px;
  width: 64px;
  height: 64px;
  & > a > svg {
    width: 64px;
    height: 64px;
    color: ${props => props.theme.colors.darkBlue};
  }
  & :hover {
    cursor: pointer;
  }
`
