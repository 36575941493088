import { useStaticQuery, graphql } from "gatsby"

const useAboutUsQuery = () => {
  const aboutUs = useStaticQuery(graphql`
    query aboutUs {
      allContentfulSocialPicture {
        edges {
          node {
            picture {
              gatsbyImageData
            }
            title
          }
        }
      }
    }
  `)
  return aboutUs
}
export default useAboutUsQuery
