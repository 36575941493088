import checkLg from "@iconify-icons/bi/check-lg"

const aboutUsListItemData = [
  {
    id: 1,
    text: "continuous improvement on the art of programing,",
    icon: checkLg,
  },
  {
    id: 2,
    text: "oak solid work",
    icon: checkLg,
  },
  {
    id: 3,
    text: "adaptability & customize",
    icon: checkLg,
  },
  {
    id: 4,
    text: "transparency",
    icon: checkLg,
  },
]

export default aboutUsListItemData
