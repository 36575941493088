import styled from "styled-components"
import { TripleGrid } from "../../../globalStyle"

export const Container = styled.div`
  width: 1470px;
  @media (max-width: 1470px) {
    width: 100%;
  }
`

export const CountersGrid = styled(TripleGrid)`
  @media (max-width: 650px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

export const TextWrapper = styled.div`
  margin-top: 0;
  padding: 0 15px 0 15px;
`
export const ButtonWrapper = styled.div`
  margin-bottom: 50px;
`
export const GridWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 100px;
  padding-bottom: 20px;
  padding-top: 20px;
`
export const GridContent = styled.div`
  padding: 30px;
  display: flex;

  flex-direction: column;
  z-index: 465;

  & > p {
    color: ${props => props.theme.colors.white};
  }
  & > div > p {
    margin: 2px 10px 2px 45px;
    color: ${props => props.theme.colors.white};
  }
  @media (max-width: 650px) {
    & > h2 {
      text-align: center;
    }
  }
  @media (max-width: 500px) {
    padding: 10px;
    & > div > p {
      margin: 2px 10px 2px 15px;
    }
  }
`
export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    width: 250px;
    height: 250px;
    margin: 10px;
  }
  &:hover {
    cursor: pointer;
  }
`

export const BackgroundWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
