import React, { PropsWithChildren } from "react"
import { P } from "../../../globalStyle"
import { InlineIcon } from "@iconify/react"
import IListItem from "./types"

const ListItem = (props: PropsWithChildren<IListItem>) => {
  const { icon, children } = props
  return (
    <P>
      <InlineIcon style={{ marginRight: "10px" }} icon={icon} />
      {children}
    </P>
  )
}

export default ListItem
