import React from "react"
import { Container, LinkedInIcon } from "./style"
import { H2Title, H3Title, Section, OrangeSpan } from "../../../../globalStyle"
import { InlineIcon } from "@iconify/react"
import linkedinFill from "@iconify-icons/akar-icons/linkedin-fill"

const MeetUs = () => {
  return (
    <Section style={{ marginTop: "100px", marginBottom: "100px" }}>
      <Container>
        <H2Title style={{ margin: " 20px 10px 0px 10px" }} center={true}>
          Meet out <OrangeSpan>team</OrangeSpan>
        </H2Title>
        <H3Title
          isDarkBlue={true}
          center={true}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Find us on linkedin{" "}
          <LinkedInIcon>
            <a href="https://www.linkedin.com/company/oakfusion/">
              <InlineIcon icon={linkedinFill} />
            </a>
          </LinkedInIcon>
        </H3Title>
      </Container>
    </Section>
  )
}

export default MeetUs
