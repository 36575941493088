import React from "react"
import {
  Container,
  TextWrapper,
  ButtonWrapper,
  GridContent,
  BackgroundWrapper,
  GridWrapper,
  ImageWrapper,
  CountersGrid,
} from "./style"
import {
  H2Title,
  Section,
  GrayBorder,
  OrangeSpan,
  P,
  DoubleGrid,
  QuadrupleGrid,
  AnimatedBackground,
} from "../../../globalStyle"
import Button from "../../UI/Button"
import aboutUsListItemData from "../../../data/components/aboutUsListItem"
import ListItem from "../../UI/ListItem"
import { useTheme } from "styled-components"
import MeetUs from "./MeetUs"
import Counter from "./Counter"
import { SRLWrapper } from "simple-react-lightbox"
import useAboutUsQuery from "../../../helpers/queries/aboutUs"
import { GatsbyImage } from "gatsby-plugin-image"

const AboutUsComponent = () => {
  const aboutUsData = useAboutUsQuery()
  const theme = useTheme()
  return (
    <Section style={{ marginTop: "100px" }}>
      <Container>
        <H2Title marginLeft="15px">About Us</H2Title>
      </Container>
      <GrayBorder />
      <Container>
        <H2Title style={{ marginBottom: "0px" }} marginLeft="15px" thin={true}>
          We have been here <OrangeSpan>since 2012</OrangeSpan>
        </H2Title>
        <TextWrapper>
          <P>
            We offer support in projects, code analysis, developing web and
            mobile apps, DevOps services and cloud solutions. Our goal is to
            create quality software using the latest stack and tools. All this
            in a great atmosphere and a high level of work culture – we are a
            geaks of sport and Java.
          </P>
        </TextWrapper>
        <ButtonWrapper>
          <Button slug="https://system.erecruiter.pl/FormTemplates/RecruitmentForm.aspx?WebID=cdf7c9be45f246f0a710c7a25fff4b90">
            Apply now
          </Button>
        </ButtonWrapper>
      </Container>
      <BackgroundWrapper>
        <AnimatedBackground></AnimatedBackground>
        <Container>
          <DoubleGrid>
            <GridContent>
              <H2Title color={theme.colors.white}>We love what we do</H2Title>
              <div>
                {aboutUsListItemData.map(i => (
                  <ListItem key={i.id} icon={i.icon}>
                    {i.text}
                  </ListItem>
                ))}
              </div>

              <P>
                In each project you will be working in agile methodology and
                using tools like Java, Java SpringBoot, React/Angular and
                DevOps.
              </P>
            </GridContent>
            <GridContent>
              <H2Title color={theme.colors.white}>Our story in numbers</H2Title>
              <CountersGrid>
                <Counter number={8} text={"years on market"} />
                <Counter number={40} text={"team members"} />
                <Counter number={69} text={"projects"} />
              </CountersGrid>
            </GridContent>
          </DoubleGrid>
        </Container>
      </BackgroundWrapper>
      <Container>
        <MeetUs />
      </Container>
      <BackgroundWrapper>
        <AnimatedBackground></AnimatedBackground>
        <Container>
          <GridWrapper>
            <GridContent>
              <H2Title center={true} color={theme.colors.white}>
                Meet us in our office
              </H2Title>
              <SRLWrapper>
                <QuadrupleGrid>
                  {aboutUsData.allContentfulSocialPicture.edges.map(
                    (e, idx) => (
                      <ImageWrapper key={idx}>
                        <GatsbyImage
                          image={e.node.picture.gatsbyImageData}
                          alt={e.node.title}
                        />
                      </ImageWrapper>
                    )
                  )}
                </QuadrupleGrid>
              </SRLWrapper>
            </GridContent>
          </GridWrapper>
        </Container>
      </BackgroundWrapper>
    </Section>
  )
}

export default AboutUsComponent
